import { observable, action } from 'mobx';

const state =  observable({
  update: action((key, value) => {
    state[key] = value;
  }),
  error: {
    is_open: false,
    size: 'lg', //[xs/sm/lg/xl]
    type: 'primary',
    title: null,
    message: null,
    button: 'Ok'
  }
});

export default state;
