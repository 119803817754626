import React, { useState, useEffect } from "react"
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from "reactstrap"
import * as Icon from "react-feather";
import { fetch_get } from "src/helper/api";
import { helper_localGet, def_lang } from "src/helper";
import { logout } from "src/pages/login/control";
import translate from "src/helper/translation";
const page = 'header';
const lang = def_lang();

export async function get_wallet_balance(profile_id) {
  return await fetch_get("/wallet/balance/" + profile_id)
    .then(value => {
      return value;
    })
}

const UserDropdown = props => {
  const handle_logout = () => {
    logout();
  }

  return (
    <DropdownMenu right>
      <DropdownItem tag="a" href={'/' + lang + '/users/change-password'}>
        <Icon.Lock size={14} className="mr-50" />
        <span className="align-middle">{translate.helper(page, 'change.password', lang)}</span>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem onClick={handle_logout}>
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">{translate.helper(page, 'log.out', lang)}</span>
      </DropdownItem>
    </DropdownMenu>
  )
}

const NavbarUser = () => {
  const [user, set_user] = useState({
    user_name: null,
    profile: {
      name: null
    },
    permission: null
  });
  const [wallet_balance, set_balance] = useState(null);

  useEffect(() => {
    if (helper_localGet("auth")) {
      set_user(helper_localGet("auth"));
      if (helper_localGet("auth").permission === "broadcaster") {
        get_wallet_balance(helper_localGet("auth").profile.id).then(value => {
          if(value.status){
            set_balance(value.result.balance);
          }
        });
      }
    }
  }, [])


  return (
    <ul className="nav navbar-nav navbar-nav-user float-right">
      <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
        <DropdownToggle tag="a" className="nav-link dropdown-user-link">
          <div className="user-nav d-sm-flex">
            <span className="user-name text-bold-600">
              {user.user_name} {user.permission === "broadcaster" && <span>({wallet_balance})</span>}
            </span>
            <span className="user-status">
              {user.profile.name ? user.profile.name + ' - ' : null} {user.permission}
            </span>
          </div>
          <img style={{ width: '40px' }} src={user.profile.avatar ? user.profile.avatar : "https://picsum.photos/40/40"} alt="avatar" className="header-avatar" />
        </DropdownToggle>
        <UserDropdown />
      </UncontrolledDropdown>
    </ul>
  )
}
export default NavbarUser
