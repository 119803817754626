import { config } from "src/store";
import { helper_removeSet, helper_localGet } from "src/helper";
// import trans from "src/helper/translation";
// eslint-disable-next-line
const lang = config.lang;

const api_error = (data) => {
  data = JSON.parse(data);
  //console.log(data, data.system);
  switch (data.system) {
    case "session.invalid":
      console.log("remove");
      helper_removeSet("auth");
      window.location = "/" + lang;
      break;
    default:
  }
};
export async function fetch_post(url, data) {
  const auth = helper_localGet("auth") ? helper_localGet("auth") : false;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  if (auth.token) {
    headers.append("token", auth.token);
  }

  data = JSON.stringify(data);

  const requestOptions = {
    method: "POST",
    headers: headers,
    body: data,
    redirect: "follow",
  };

  return await fetch(process.env.API + url, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      if (!result.status) {
        api_error(result);
      }
      result = JSON.parse(result);
      return result;
    })
    .catch((error) => {
      console.log("error", error);
      return false;
    });
}
export async function fetch_get(url, data) {
  const auth = helper_localGet("auth") ? helper_localGet("auth") : false;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  if (auth.token) {
    headers.append("token", auth.token);
  }

  data = JSON.stringify(data);

  const requestOptions = {
    method: "GET",
    headers: headers,
    redirect: "follow",
  };

  return await fetch(process.env.API + url, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      if (!result.status) {
        api_error(result);
      }
      result = JSON.parse(result);
      return result;
    })
    .catch((error) => {
      console.log("error", error);
      return false;
    });
}
export async function fetch_get2(url, data) {
  const auth = helper_localGet("auth") ? helper_localGet("auth") : false;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  if (auth.token) {
    headers.append("token", auth.token);
  }

  data = JSON.stringify(data);

  const requestOptions = {
    method: "GET",
    headers: headers,
    redirect: "follow",
  };

  return await fetch("https://api2.klzpapp01.com" + url, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      if (!result.status) {
        api_error(result);
      }
      result = JSON.parse(result);
      return result;
    })
    .catch((error) => {
      console.log("error", error);
      return false;
    });
}
