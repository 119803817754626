import {config} from 'src/store';

// app initialize
// console.log('%cStop! ', 'color: red; font-size: 30px; font-weight: bold;');
// console.log('%cThis is a browser feature intended for developers. If someone told you to copy-paste something here, it is a scam and we, at noisybet will never ask you to paste anything here. ', 'font-size: 20px;');

if (process.env.ENV === "live") {
  let isHttpsRaw = window.location.href;
  let isHttps = isHttpsRaw.split(":");
  if (isHttps[0] !== "https") {
    isHttps = isHttpsRaw.replace("http", "https");
    window.location.href = isHttps;
  }
}

// fix so that back and forward button of the browser will work.
window.addEventListener("popstate", e => {
  // eslint-disable-next-line
  window.location.href = window.location.href;
});

function pageResize() {
  let content = document.getElementById('content');
  if(content){
    document.getElementById('content').style.height = (window.innerHeight) +'px'; //(window.innerHeight - 68) +'px';
  }
}

// when page is already loaded.
window.addEventListener('load', event => {
  pageResize();
});
// when page is resized.
window.addEventListener('resize', event => {
  pageResize();
});

// url language correction
(function() {
  // this method will help set the default language.
  // get selected language
  const url = window.location.href;
  let urlPath = url.split('/');
  let urlLang = null;

  // get language from url
  urlLang = urlPath[3];

  // // return available regions
  let regions = config.regions;
  regions = Object.entries(regions).map((val, key)=>{
      return val[0];
  });

  // check if selected language exist.
  // if exist, set to config default language.
  // if do not exist, use default language.
  // config.lang = urlLang;
  config.update('lang', urlLang);
  if(!regions.includes(urlLang)){
    const default_lang = (process.env.ENV === 'dev') ? 'en' : 'cn';
    // config.lang = default_lang;
    config.update('lang', default_lang);
    urlPath.splice(3, 0, default_lang); // insert to index 3 - default language
    urlPath = urlPath.join('/');
    window.location.replace(urlPath);
  }
})();
