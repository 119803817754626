import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Layout } from "src/utility/context/Layout";
import { store } from "src/redux/storeConfig/store"
import Spinner from "./components/@vuexy/spinner/Fallback-spinner";
// import App from "./app";
import unregister from "./configs/registerServiceWorker";
import "./assets/scss/index.scss";
import "src/components/@vuexy/rippleButton/RippleButton";
import "./assets/css/style.scss";
import "./init.js";

const LazyApp = lazy(() => import("./app"))

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <Layout>
          <LazyApp />
      </Layout>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);
unregister();
