import React from "react";
import * as Icon from "react-feather";
import { date_day_count, helper_localGet, def_lang } from "src/helper";
import translate from "src/helper/translation";
const page = "left.nav";
let lang = def_lang();
let user = helper_localGet("auth");
console.log(user, "nav user");
// export function navigationConfig() {
const navigationConfig = () => {
  let admin_nav = [
    // {
    //   id: "dashboard",
    //   title: translate.helper(page, 'dashboard', lang),
    //   type: "item",
    //   icon: <Icon.Home size={20} />,
    //   permissions: ["admin"],
    //   navLink: "/" + lang + "/dashboard"
    // },
    // {
    //   type: "groupHeader",
    //   groupTitle: translate.helper(page, "sports", lang),
    //   permissions: ["admin"],
    // },
    // {
    //   id: "football",
    //   title: translate.helper(page, "football", lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["admin"],
    //   navLink: "/" + lang + "/sports/football/:date_from/:date_to/:page",
    //   filterBase:
    //     "/" +
    //     lang +
    //     "/sports/football/" +
    //     date_day_count(0) +
    //     "/" +
    //     date_day_count(1) +
    //     "/1",
    // },
    // {
    //   id: "basketball",
    //   title: translate.helper(page, "basketball", lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["admin"],
    //   navLink: "/" + lang + "/sports/basketball/:date_from/:date_to/:page",
    //   filterBase:
    //     "/" +
    //     lang +
    //     "/sports/basketball/" +
    //     date_day_count(0) +
    //     "/" +
    //     date_day_count(1) +
    //     "/1",
    // },
    // {
    //   id: "lol",
    //   title: translate.helper(page, 'lol', lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["admin"],
    //   navLink: "/" + lang + "/sports/lol/:date_from/:date_to/:page",
    //   filterBase: "/" + lang + "/sports/lol/" + date_day_count(0) + "/" + date_day_count(1) + "/1"
    // },
    // {
    //   id: "csgo",
    //   title: translate.helper(page, 'csgo', lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["admin"],
    //   navLink: "/" + lang + "/sports/csgo/:date_from/:date_to/:page",
    //   filterBase: "/" + lang + "/sports/csgo/" + date_day_count(0) + "/" + date_day_count(1) + "/1"
    // },
    // {
    //   id: "dota2",
    //   title: translate.helper(page, 'dota2', lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["admin"],
    //   navLink: "/" + lang + "/sports/dota2/:date_from/:date_to/:page",
    //   filterBase: "/" + lang + "/sports/dota2/" + date_day_count(0) + "/" + date_day_count(1) + "/1"
    // },
    // {
    //   id: "kog",
    //   title: translate.helper(page, 'kog', lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["admin"],
    //   navLink: "/" + lang + "/sports/kog/:date_from/:date_to/:page",
    //   filterBase: "/" + lang + "/sports/kog/" + date_day_count(0) + "/" + date_day_count(1) + "/1"
    // },
    // {
    //   type: "groupHeader",
    //   groupTitle: translate.helper(page, "bet.prediction", lang),
    //   permissions: ["admin"],
    // },
    // {
    //   id: "predict_list",
    //   title: translate.helper(page, "predict.list", lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["admin"],
    //   navLink: "/" + lang + "/bet_prediction/list/:sport/:page",
    //   filterBase: "/" + lang + "/bet_prediction/list/all/1",
    // },
    // {
    //   id: "predict_statement",
    //   title: translate.helper(page, "predict.statement", lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["admin"],
    //   navLink: "/" + lang + "/bet_prediction/statement",
    //   filterBase: "/" + lang + "/bet_prediction/statement",
    // },
    {
      type: "groupHeader",
      groupTitle: translate.helper(page, "broadcasters", lang),
      permissions: ["admin"],
    },
    {
      id: "bc_schedule",
      title: translate.helper(page, "bc.schedule", lang),
      type: "item",
      icon: <Icon.Circle size={12} />,
      permissions: ["admin"],
      navLink:
        "/" +
        lang +
        "/broadcasters/schedule/:sport/:date/:status/:page/:keyword",
      filterBase:
        "/" +
        lang +
        "/broadcasters/schedule/all/" +
        date_day_count(0) +
        "/all/1/$",
    },
    {
      id: "bc_list",
      title: translate.helper(page, "bc.list", lang),
      type: "item",
      icon: <Icon.Circle size={12} />,
      permissions: ["admin"],
      navLink: "/" + lang + "/broadcasters/list/:status/:search/:page",
      filterBase: "/" + lang + "/broadcasters/list/all/$/1",
    },
    // {
    //   id: "bc_leadership_board",
    //   title: translate.helper(page, 'leadership.board', lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["admin"],
    //   navLink: "/" + lang + "/broadcasters/leadership/:page",
    //   filterBase: "/" + lang + "/broadcasters/leadership/1"
    // },
    // {
    //   id: "bc_application",
    //   title: translate.helper(page, "application", lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["admin"],
    //   navLink: "/" + lang + "/broadcasters/application/:page",
    //   filterBase: "/" + lang + "/broadcasters/application/1",
    // },
    {
      id: "teams",
      title: translate.helper(page, "teams", lang),
      type: "item",
      icon: <Icon.Circle size={12} />,
      permissions: ["admin"],
      navLink: "/" + lang + "/team/:page/:keyword",
      filterBase: "/" + lang + "/team/1/$",
    },
    {
      type: "groupHeader",
      groupTitle: translate.helper(page, "members", lang),
      permissions: ["admin"],
    },
    {
      id: "member_list",
      title: translate.helper(page, "member.list", lang),
      type: "item",
      icon: <Icon.Circle size={12} />,
      permissions: ["admin"],
      navLink:
        "/" +
        lang +
        "/members/list/:status/:search/:date_type/:date_from/:date_to/:page",
      filterBase: "/" + lang + "/members/list/all/$/all/$/$/1",
    },
    {
      type: "groupHeader",
      groupTitle: translate.helper(page, "users", lang),
      permissions: ["admin"],
    },
    {
      id: "users",
      title: translate.helper(page, "user.list", lang),
      type: "item",
      icon: <Icon.Circle size={12} />,
      permissions: ["admin"],
      navLink: "/" + lang + "/users",
    },
    {
      id: "op_record",
      title: translate.helper(page, "op.record", lang),
      type: "item",
      icon: <Icon.Circle size={12} />,
      permissions: ["admin"],
      navLink: "/" + lang + "/logs/1/:keyword",
      filterBase: "/" + lang + "/logs/1/$",
    },
    {
      type: "groupHeader",
      groupTitle: translate.helper(page, "setting", lang),
      permissions: ["admin"],
    },
    // {
    //   id: "tracking_url",
    //   title: translate.helper(page, "tracking", lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["admin"],
    //   navLink: "/" + lang + "/tracking-url/:page",
    //   filterBase: "/" + lang + "/tracking-url/1",
    // },
    {
      id: "ip_blacklist",
      title: translate.helper(page, "ip.blacklist", lang),
      type: "item",
      icon: <Icon.Circle size={12} />,
      permissions: ["admin"],
      navLink: "/" + lang + "/ip-blacklist/:page",
      filterBase: "/" + lang + "/ip-blacklist/1",
    },
    // {
    //   id: "guest_blacklist",
    //   title: translate.helper(page, "guest.blacklist", lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["admin"],
    //   navLink: "/" + lang + "/guest-blacklist/:page",
    //   filterBase: "/" + lang + "/guest-blacklist/1",
    // },
    {
      id: "chat_wordblock",
      title: translate.helper(page, "chat.word.block", lang),
      type: "item",
      icon: <Icon.Circle size={12} />,
      permissions: ["admin"],
      navLink: "/" + lang + "/setting/chat_wordblock/:page",
      filterBase: "/" + lang + "/setting/chat_wordblock/1",
    },
    {
      id: "banner",
      title: translate.helper(page, "banner", lang),
      type: "item",
      icon: <Icon.Circle size={12} />,
      permissions: ["admin"],
      navLink: "/" + lang + "/banner/:page",
      filterBase: "/" + lang + "/banner/1",
    },
    {
      id: "chatbanner",
      title: translate.helper(page, "chat.banner", lang),
      type: "item",
      icon: <Icon.Circle size={12} />,
      permissions: ["admin"],
      navLink: "/" + lang + "/chat-banner/:page",
      filterBase: "/" + lang + "/chat-banner/1",
    },
    // {
    //   id: "splash_screen",
    //   title: translate.helper(page, "splash.screen", lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["admin"],
    //   navLink: "/" + lang + "/splash_screen",
    //   filterBase: "/" + lang + "/splash_screen",
    // },
    // {
    //   id: "promotion",
    //   title: translate.helper(page, "promotion", lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["admin"],
    //   navLink: "/" + lang + "/promotion/list/:page",
    //   filterBase: "/" + lang + "/promotion/list/1",
    // },
    // {
    //   id: "fake_message",
    //   title: translate.helper(page, "fake.message", lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["admin"],
    //   navLink: "/" + lang + "/fake_message/list/:group/:page",
    //   filterBase: "/" + lang + "/fake_message/list/all/1",
    // },
    // {
    //   id: "fake_member",
    //   title: translate.helper(page, "fake.member", lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["admin"],
    //   navLink: "/" + lang + "/fake_member/list/:group/:page",
    //   filterBase: "/" + lang + "/fake_member/list/all/1",
    // },
    {
      id: "ads",
      title: translate.helper(page, "ads", lang),
      type: "item",
      icon: <Icon.Circle size={12} />,
      permissions: ["admin"],
      navLink: "/" + lang + "/ads/:page",
      filterBase: "/" + lang + "/ads/1",
    },
    {
      type: "groupHeader",
      groupTitle: translate.helper(page, "announcement", lang),
      permissions: ["admin"],
    },
    {
      id: "chat_room",
      title: translate.helper(page, "chat.room", lang),
      type: "item",
      icon: <Icon.Circle size={12} />,
      permissions: ["admin"],
      navLink: "/" + lang + "/announcement/chatroom/:page",
      filterBase: "/" + lang + "/announcement/chatroom/1",
    },
  ];

  let broadcaster_nav = [
    {
      id: "my_schedule",
      title: " 我的直播表",
      type: "item",
      icon: <Icon.Play size={20} />,
      permissions: ["broadcaster"],
      navLink: "/" + lang + "/schedule/list/:sport/:date/:page",
      filterBase: "/" + lang + "/schedule/list/all/" + date_day_count(0) + "/1",
    },
    {
      id: "bc_schedule",
      title: translate.helper(page, "bc.schedule", lang),
      type: "item",
      icon: <Icon.Circle size={12} />,
      permissions: ["broadcaster"],
      navLink:
        "/" +
        lang +
        "/broadcasters/schedule/:sport/:date/:status/:page/:keyword",
      filterBase:
        "/" +
        lang +
        "/broadcasters/schedule/all/" +
        date_day_count(0) +
        "/all/1/$",
    },

    {
      id: "my_profile",
      title: translate.helper(page, "my.profile", lang),
      type: "item",
      icon: <Icon.User size={20} />,
      permissions: ["broadcaster"],
      navLink:
        "/" + lang + "/broadcasters/profile/:profile_id/:type/:status/:page",
      filterBase:
        "/" + lang + "/broadcasters/profile/" + user.profile.id + "/all/all/1",
    },
    // {
    //   id: "dashboard",
    //   title: translate.helper(page, "dashboard", lang),
    //   type: "item",
    //   icon: <Icon.Home size={20} />,
    //   permissions: ["broadcaster"],
    //   navLink: "/" + lang + "/dashboard",
    // },
    {
      type: "groupHeader",
      groupTitle: translate.helper(page, "sports", lang),
      permissions: ["broadcaster"],
    },
    {
      id: "football",
      title: translate.helper(page, "football", lang),
      type: "item",
      icon: <Icon.Circle size={12} />,
      permissions: ["broadcaster"],
      navLink: "/" + lang + "/sports/football/:date_from/:date_to/:page",
      filterBase:
        "/" +
        lang +
        "/sports/football/" +
        date_day_count(0) +
        "/" +
        date_day_count(1) +
        "/1",
    },
    {
      id: "basketball",
      title: translate.helper(page, "basketball", lang),
      type: "item",
      icon: <Icon.Circle size={12} />,
      permissions: ["broadcaster"],
      navLink: "/" + lang + "/sports/basketball/:date_from/:date_to/:page",
      filterBase:
        "/" +
        lang +
        "/sports/basketball/" +
        date_day_count(0) +
        "/" +
        date_day_count(1) +
        "/1",
    },
    // {
    //   id: "lol",
    //   title: translate.helper(page, 'lol', lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["broadcaster"],
    //   navLink: "/" + lang + "/sports/lol/:date_from/:date_to/:page",
    //   filterBase: "/" + lang + "/sports/lol/" + date_day_count(0) + "/" + date_day_count(1) + "/1"
    // },
    // {
    //   id: "csgo",
    //   title: translate.helper(page, 'csgo', lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["broadcaster"],
    //   navLink: "/" + lang + "/sports/csgo/:date_from/:date_to/:page",
    //   filterBase: "/" + lang + "/sports/csgo/" + date_day_count(0) + "/" + date_day_count(1) + "/1"
    // },
    // {
    //   id: "dota2",
    //   title: translate.helper(page, 'dota2', lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["broadcaster"],
    //   navLink: "/" + lang + "/sports/dota2/:date_from/:date_to/:page",
    //   filterBase: "/" + lang + "/sports/dota2/" + date_day_count(0) + "/" + date_day_count(1) + "/1"
    // },
    // {
    //   id: "kog",
    //   title: translate.helper(page, 'kog', lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["broadcaster"],
    //   navLink: "/" + lang + "/sports/kog/:date_from/:date_to/:page",
    //   filterBase: "/" + lang + "/sports/kog/" + date_day_count(0) + "/" + date_day_count(1) + "/1"
    // },
    // {
    //   type: "groupHeader",
    //   groupTitle: translate.helper(page, "bet.prediction", lang),
    //   permissions: ["broadcaster"],
    // },
    // {
    //   id: "predict_list",
    //   title: translate.helper(page, "predict.list", lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["broadcaster"],
    //   navLink: "/" + lang + "/bet_prediction/list/:sport/:page",
    //   filterBase: "/" + lang + "/bet_prediction/list/all/1",
    // },

    // {
    //   id: "predict_statement",
    //   title: translate.helper(page, "predict.statement", lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["broadcaster"],
    //   navLink: "/" + lang + "/bet_prediction/statement",
    //   filterBase: "/" + lang + "/bet_prediction/statement",
    // },
    // {
    //   type: "groupHeader",
    //   groupTitle: translate.helper(page, "broadcasters", lang),
    //   permissions: ["broadcaster"],
    // },
    // {
    //   id: "bc_schedule",
    //   title: translate.helper(page, "bc.schedule", lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["broadcaster"],
    //   navLink: "/" + lang + "/broadcasters/schedule/:sport/:date/:status/:page",
    //   filterBase:
    //     "/" +
    //     lang +
    //     "/broadcasters/schedule/all/" +
    //     date_day_count(0) +
    //     "/all/1",
    // },
    // {
    //   id: "bc_list",
    //   title: translate.helper(page, "bc.list", lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["broadcaster"],
    //   navLink: "/" + lang + "/broadcasters/list/:status/:search/:page",
    //   filterBase: "/" + lang + "/broadcasters/list/all/$/1",
    // },
    // {
    //   id: "bc_leadership_board",
    //   title: translate.helper(page, 'leadership.board', lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["broadcaster"],
    //   navLink: "/" + lang + "/broadcasters/leadership/:page",
    //   filterBase: "/" + lang + "/broadcasters/leadership/1"
    // },
    // {
    //   id: "bc_application",
    //   title: translate.helper(page, "application", lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["broadcaster"],
    //   navLink: "/" + lang + "/broadcasters/application/:page",
    //   filterBase: "/" + lang + "/broadcasters/application/1",
    // },

    {
      type: "groupHeader",
      groupTitle: translate.helper(page, "members", lang),
      permissions: ["broadcaster"],
    },
    {
      id: "member_list",
      title: translate.helper(page, "member.list", lang),
      type: "item",
      icon: <Icon.Circle size={12} />,
      permissions: ["broadcaster"],
      navLink:
        "/" +
        lang +
        "/members/list/:status/:search/:date_type/:date_from/:date_to/:page",
      filterBase: "/" + lang + "/members/list/all/$/all/$/$/1",
    },
    // {
    //   type: "groupHeader",
    //   groupTitle: translate.helper(page, "users", lang),
    //   permissions: ["broadcaster"],
    // },
    // {
    //   id: "users",
    //   title: translate.helper(page, "user.list", lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["broadcaster"],
    //   navLink: "/" + lang + "/users",
    // },
    // {
    //   type: "groupHeader",
    //   groupTitle: translate.helper(page, "setting", lang),
    //   permissions: ["broadcaster"],
    // },
    // {
    //   id: "tracking_url",
    //   title: translate.helper(page, "tracking", lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["broadcaster"],
    //   navLink: "/" + lang + "/tracking-url/:page",
    //   filterBase: "/" + lang + "/tracking-url/1",
    // },
    // {
    //   id: "ip_blacklist",
    //   title: translate.helper(page, "ip.blacklist", lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["broadcaster"],
    //   navLink: "/" + lang + "/ip-blacklist/:page",
    //   filterBase: "/" + lang + "/ip-blacklist/1",
    // },
    // {
    //   id: "guest_blacklist",
    //   title: translate.helper(page, "guest.blacklist", lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["broadcaster"],
    //   navLink: "/" + lang + "/guest-blacklist/:page",
    //   filterBase: "/" + lang + "/guest-blacklist/1",
    // },
    // {
    //   id: "chat_wordblock",
    //   title: translate.helper(page, "chat.word.block", lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["broadcaster"],
    //   navLink: "/" + lang + "/setting/chat_wordblock/:page",
    //   filterBase: "/" + lang + "/setting/chat_wordblock/1",
    // },
    // {
    //   id: "banner",
    //   title: translate.helper(page, "banner", lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["broadcaster"],
    //   navLink: "/" + lang + "/banner/:page",
    //   filterBase: "/" + lang + "/banner/1",
    // },
    {
      id: "chatbanner",
      title: translate.helper(page, "chat.banner", lang),
      type: "item",
      icon: <Icon.Circle size={12} />,
      permissions: ["broadcaster"],
      navLink: "/" + lang + "/chat-banner/:page",
      filterBase: "/" + lang + "/chat-banner/1",
    },
    // {
    //   id: "splash_screen",
    //   title: translate.helper(page, "splash.screen", lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["broadcaster"],
    //   navLink: "/" + lang + "/splash_screen",
    //   filterBase: "/" + lang + "/splash_screen",
    // },
    // {
    //   id: "promotion",
    //   title: translate.helper(page, "promotion", lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["broadcaster"],
    //   navLink: "/" + lang + "/promotion/list/:page",
    //   filterBase: "/" + lang + "/promotion/list/1",
    // },
    // {
    //   id: "fake_message",
    //   title: translate.helper(page, "fake.message", lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["broadcaster"],
    //   navLink: "/" + lang + "/fake_message/list/:group/:page",
    //   filterBase: "/" + lang + "/fake_message/list/all/1",
    // },
    // {
    //   id: "fake_member",
    //   title: translate.helper(page, "fake.member", lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["broadcaster"],
    //   navLink: "/" + lang + "/fake_member/list/:group/:page",
    //   filterBase: "/" + lang + "/fake_member/list/all/1",
    // },
    // {
    //   type: "groupHeader",
    //   groupTitle: translate.helper(page, "announcement", lang),
    //   permissions: ["broadcaster"],
    // },
    // {
    //   id: "chat_room",
    //   title: translate.helper(page, "chat.room", lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["broadcaster"],
    //   navLink: "/" + lang + "/announcement/chatroom/:page",
    //   filterBase: "/" + lang + "/announcement/chatroom/1",
    // },
  ];

  let manager_nav = [
    // {
    //   id: "football",
    //   title: translate.helper(page, "football", lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["manager"],
    //   navLink: "/" + lang + "/sports/football/:date_from/:date_to/:page",
    //   filterBase:
    //     "/" +
    //     lang +
    //     "/sports/football/" +
    //     date_day_count(0) +
    //     "/" +
    //     date_day_count(1) +
    //     "/1",
    // },

    {
      type: "groupHeader",
      groupTitle: translate.helper(page, "broadcasters", lang),
      permissions: ["manager"],
    },
    {
      id: "bc_schedule",
      title: translate.helper(page, "bc.schedule", lang),
      type: "item",
      icon: <Icon.Circle size={12} />,
      permissions: ["manager"],
      navLink:
        "/" +
        lang +
        "/broadcasters/schedule/:sport/:date/:status/:page/:keyword",
      filterBase:
        "/" +
        lang +
        "/broadcasters/schedule/all/" +
        date_day_count(0) +
        "/all/1/$",
    },
    {
      id: "bc_list",
      title: translate.helper(page, "bc.list", lang),
      type: "item",
      icon: <Icon.Circle size={12} />,
      permissions: ["manager"],
      navLink: "/" + lang + "/broadcasters/list/:status/:search/:page",
      filterBase: "/" + lang + "/broadcasters/list/all/$/1",
    },
    user?.teams?.length !== 0 && {
      id: "teams",
      title: translate.helper(page, "teams", lang),
      type: "item",
      icon: <Icon.Circle size={12} />,
      permissions: ["manager"],
      navLink: "/" + lang + "/team/:id/:page/:keyword",
      filterBase: "/" + lang + "/team/" + user?.teams[0]?._id + "/1/$",
    },
    {
      type: "groupHeader",
      groupTitle: translate.helper(page, "members", lang),
      permissions: ["manager"],
    },
    {
      id: "member_list",
      title: translate.helper(page, "member.list", lang),
      type: "item",
      icon: <Icon.Circle size={12} />,
      permissions: ["manager"],
      navLink:
        "/" +
        lang +
        "/members/list/:status/:search/:date_type/:date_from/:date_to/:page",
      filterBase: "/" + lang + "/members/list/all/$/all/$/$/1",
    },
    {
      type: "groupHeader",
      groupTitle: translate.helper(page, "users", lang),
      permissions: ["manager"],
    },
    {
      id: "users",
      title: translate.helper(page, "user.list", lang),
      type: "item",
      icon: <Icon.Circle size={12} />,
      permissions: ["manager"],
      navLink: "/" + lang + "/users",
    },
    {
      type: "groupHeader",
      groupTitle: translate.helper(page, "users", lang),
      permissions: ["manager"],
    },
    {
      id: "op_record",
      title: translate.helper(page, "op.record", lang),
      type: "item",
      icon: <Icon.Circle size={12} />,
      permissions: ["manager"],
      navLink: "/" + lang + "/logs/1/:keyword",
      filterBase: "/" + lang + "/logs/1/$",
    },
    {
      type: "groupHeader",
      groupTitle: translate.helper(page, "setting", lang),
      permissions: ["manager"],
    },
    // {
    //   id: "tracking_url",
    //   title: translate.helper(page, "tracking", lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["manager"],
    //   navLink: "/" + lang + "/tracking-url/:page",
    //   filterBase: "/" + lang + "/tracking-url/1",
    // },

    {
      id: "chat_wordblock",
      title: translate.helper(page, "chat.word.block", lang),
      type: "item",
      icon: <Icon.Circle size={12} />,
      permissions: ["manager"],
      navLink: "/" + lang + "/setting/chat_wordblock/:page",
      filterBase: "/" + lang + "/setting/chat_wordblock/1",
    },
    {
      id: "banner",
      title: translate.helper(page, "banner", lang),
      type: "item",
      icon: <Icon.Circle size={12} />,
      permissions: ["manager"],
      navLink: "/" + lang + "/banner/:page",
      filterBase: "/" + lang + "/banner/1",
    },

    // {
    //   id: "fake_message",
    //   title: translate.helper(page, "fake.message", lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["manager"],
    //   navLink: "/" + lang + "/fake_message/list/:group/:page",
    //   filterBase: "/" + lang + "/fake_message/list/all/1",
    // },
    // {
    //   id: "fake_member",
    //   title: translate.helper(page, "fake.member", lang),
    //   type: "item",
    //   icon: <Icon.Circle size={12} />,
    //   permissions: ["manager"],
    //   navLink: "/" + lang + "/fake_member/list/:group/:page",
    //   filterBase: "/" + lang + "/fake_member/list/all/1",
    // },
    {
      type: "groupHeader",
      groupTitle: translate.helper(page, "announcement", lang),
      permissions: ["manager"],
    },
    {
      id: "chat_room",
      title: translate.helper(page, "chat.room", lang),
      type: "item",
      icon: <Icon.Circle size={12} />,
      permissions: ["manager"],
      navLink: "/" + lang + "/announcement/chatroom/:page",
      filterBase: "/" + lang + "/announcement/chatroom/1",
    },
  ];

  let member_nav = [
    {
      id: "dashboard",
      title: translate.helper(page, "dashboard", lang),
      type: "item",
      icon: <Icon.Home size={20} />,
      permissions: ["member"],
      navLink: "/" + lang + "/dashboard",
    },
    {
      type: "groupHeader",
      groupTitle: translate.helper(page, "members", lang),
      permissions: ["member"],
    },
    {
      id: "member_list",
      title: translate.helper(page, "member.list", lang),
      type: "item",
      icon: <Icon.Circle size={12} />,
      permissions: ["member"],
      navLink:
        "/" +
        lang +
        "/members/list/:status/:search/:date_type/:date_from/:date_to/:page",
      filterBase: "/" + lang + "/members/list/all/$/all/$/$/1",
    },
  ];

  let dic = {
    admin: admin_nav,
    broadcaster: broadcaster_nav,
    manager: manager_nav,
    member: member_nav,
  };

  return dic[user.permission];
};

export default navigationConfig;
